<template>
	<main>
		<HeaderTab :title="$t('global.tracking_emails')"  :return_action="'navigationBack'"/>

		<div id="content">
			<div class="container-fluid">
				<div class="row">
					<div class="col-12">
						<div class="box">
							<div class="row align-items-center">
								<div class="col">
									<h3 class="mb-0">{{ $t('emails.stats') }}</h3>
								</div>
								<div class="col-auto">
									<b-button v-b-toggle.invoiceStat variant="primary" class="mb-0">{{ $t('invoice.see_stats') }} <font-awesome-icon :icon="['fal', 'angle-down']" /><font-awesome-icon :icon="['fal', 'angle-up']" /></b-button>
								</div>
							</div>
							<b-collapse id="invoiceStat">
								<div class="row pt-4">
								<b-table striped hover :items="stat" :fields="fields_stat"></b-table>
								</div>
							</b-collapse>
						</div>


						<div class="box">
                            <DateRange
								:start.sync="start_date"
								:end.sync="end_date"
								@submit="loadTracking"
								:ShowTodayButton="true"
							/>

							<CustomTable
								ref="emails"
								id_table="emails_mandrill"
								:items="trackings"
								:busy.sync="table_busy"
								primaryKey="mandrilltracking_id"
								:hide_if_empty="true"
                				:columsAddBegin="['preview']"
								:externSlotColumns="extern_slot_columns"
							>
								<template v-slot:[`custom-slot-cell(preview)`]="{ data }">
									<a href="" class="" @click.prevent="preview(data.mandrilltracking_id)">
										<font-awesome-icon :icon="['fas', 'eye']" />
									</a>
								</template>
								<template v-slot:[`custom-slot-cell(mandrilltracking_archived)`]="{ data }">
									<font-awesome-icon class="text-success" v-if="data.mandrilltracking_archived" :icon="['fal', 'check-circle']" />
									<font-awesome-icon class="text-danger" v-else :icon="['fal', 'times-circle']" />
								</template>
								<template v-slot:[`custom-slot-cell(mandrilltracking_status)`]="{ data }">
									{{ $t('emails.' + data.mandrilltracking_status) }}
								</template>
							</CustomTable>

						</div>
					</div>
				</div>
			</div>
		</div>
		<b-modal size="xl" ref="modelPreview" hide-footer @hidden="base64 = null">
            <template v-slot:modal-title>
                {{ $t("action.previsualiser") }}
            </template>

            <iframe v-if="base64 != null" :srcdoc=" base64" height="1000px" width="100%" style="position:relative"></iframe>
            <div v-else>
                <LoadingSpinner />
            </div>
        </b-modal>
	</main>
</template>

<script type="text/javascript">
import Config from "@/mixins/Config.js"

import Emails from "@/mixins/Emails.js"
import Invoice from "@/mixins/Invoice.js"
import Navigation from "@/mixins/Navigation.js"
import Tools from "@/mixins/Tools.js";

export default {
	name: "EmailsMandrill",
	mixins: [Config, Emails, Invoice, Navigation, Tools],
	data () {
		return {
            start_date: new Date(new Date().setDate(new Date().getMonth() - 4)),
            end_date: new Date(),
			trackings: [],
			table_busy: false,
			base64: null,
            processing: false,
			stat: [{
				'send' : 0,
				'queued' : 0,
				'open' : 0,
				'hard_bounce' : 0,
				'soft_bounce' : 0,
				'reject' : 0,
			}],
			fields_stat: [
				{key: 'queued', label: this.getTrad('emails.queued'), sortable: true, tdClass: 'text-right'},
				{key: 'send', label: this.getTrad('emails.send'), sortable: true, tdClass: 'text-right'},
				{key: 'open', label: this.getTrad('emails.open'), sortable: true, tdClass: 'text-right'},
				{key: 'soft_bounce', label: this.getTrad('emails.soft_bounce'), sortable: true, tdClass: 'text-right'},
				{key: 'hard_bounce', label: this.getTrad('emails.hard_bounce'), sortable: true, tdClass: 'text-right'},
				{key: 'reject', label: this.getTrad('emails.reject'), sortable: true, tdClass: 'text-right'},
			],
			extern_slot_columns: ['preview', 'mandrilltracking_archived', 'mandrilltracking_status'],
			events_tab: {
				'TableAction::goToArchiveTracking': (trackings_ids) => {
					this.archiveTrackings(trackings_ids)
					.then(() => {
						this.init_component()
						this.unselectAll()
					})
				}
			},
		}
	},
	created() {
		this.init_component()
	},
	methods: {
		async init_component() {
			await this.loadTracking()
		},

		async loadTracking() {
			this.table_busy = true
			let trackings = await this.loadEmailsTracking(this.start_date, this.end_date)
			this.trackings = await this.formatTracking(trackings)
			this.table_busy = false
		},

		async formatTracking(trackings) {
			let tab = []

			for (let i = 0; i < trackings.length; i++) {
				const element = trackings[i];
				let tab_extern = []
				if(element.extern) {
					for (let j = 0; j < element.extern.length; j++) {
						const extern_element = element.extern[j];
						if(element.mandrilltracking_extern_table == 'invoice') {
							tab_extern.push(extern_element.invoice_num)
						}
						else if(element.mandrilltracking_extern_table == 'contract') {
							tab_extern.push(extern_element.contract_num)
						}
						else if(element.mandrilltracking_extern_table == 'quotation') {
							tab_extern.push(extern_element.quotation_num)
						}
						else if(element.mandrilltracking_extern_table == 'tiers') {
							tab_extern.push(extern_element.tiers_rs)
						}

						this.stat[0][element.mandrilltracking_status] += 1
					}
				}

				if(element.mandrilltracking_timestamp) {
					const date = new Date(element.mandrilltracking_timestamp)
					element.mandrilltracking_sended = this.formatDate(date) + ' ' + date.getHours() + ':' + date.getMinutes() + ':' + date.getSeconds()
				}

				element.extern_num = tab_extern.join(", ")
				tab.push(element)
			}

			return tab
		},

		async preview(mandrilltracking_id) {
			let email = this.trackings.find(elem =>  elem.mandrilltracking_id == mandrilltracking_id)
			this.base64 = email.mandrilltracking_content
            this.$refs.modelPreview.show()
		},
       
		unselectAll() {
			this.$refs.emails.unselectAll()
		}
	},
    watch: {

    },
	components: {
		CustomTable : () => import('GroomyRoot/components/Table/CustomTable'),
        HeaderTab: () => import('@/components/HeaderTab'),
		DateRange: () => import('GroomyRoot/components/Inputs/DateRange'),
	}
}
</script>
